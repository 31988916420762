<template>
  <div>
    <base-dialog small :show="displayLogout" @close="displayLogout = false">
      <div class="logout">
        <img
          class="d-block mx-auto"
          src="../assets/images/profile/door.svg"
          alt=""
        />
        <p class="text-big font-weight-bold text-center">
          ต้องการออกจากระบบหรือไม่ ?
        </p>
        <div class="text-center">
          <button class="cancel" @click="displayLogout = false">
            <p class="text-big">ยกเลิก</p>
          </button>
          <button class="confirm" @click="logout">
            <p class="text-big">ยืนยัน</p>
          </button>
        </div>
      </div>
    </base-dialog>

    <the-header />
    <div class="d-block d-md-none">
      <div class="d-flex justify-content-around mobile-menu">
        <div
          class="item"
          :class="{ selected: $route.path.includes('profile') }"
          @click="$router.push({ name: 'Profile' })"
        >
          <img src="../assets/images/profile/profile-icon.svg" alt="" />
        </div>
        <div
          class="item"
          :class="{ selected: $route.path.includes('mycourse') }"
          @click="$router.push({ name: 'MyCourse' })"
        >
          <img src="../assets/images/profile/course-icon.svg" alt="" />
        </div>
        <div
          class="item"
          :class="{ selected: $route.path.includes('suggestion') }"
          @click="$router.push({ name: 'Suggestion' })"
        >
          <img src="../assets/images/profile/group-icon.svg" alt="" />
        </div>
        <div
          class="item"
          :class="{ selected: $route.path.includes('paymenthistory') }"
          @click="$router.push({ name: 'PayHistory' })"
        >
          <img src="../assets/images/profile/history-icon.svg" alt="" />
        </div>
        <div class="item" @click="displayLogout = true">
          <img src="../assets/images/profile/logout-icon.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <div class="row">
        <div class="d-none d-md-block col-md-4">
          <div class="menu">
            <div
              class="item d-flex"
              :class="{ selected: $route.path.includes('profile') }"
              @click="$router.push({ name: 'Profile' })"
            >
              <img src="../assets/images/profile/profile-icon.svg" alt="" />
              <p class="text-big">รายละเอียดบัญชี</p>
            </div>
            <div
              class="item d-flex"
              :class="{ selected: $route.path.includes('mycourse') }"
              @click="$router.push({ name: 'MyCourse' })"
            >
              <img src="../assets/images/profile/course-icon.svg" alt="" />
              <p class="text-big">คอร์สเรียนของฉัน</p>
            </div>
            <div
              class="item d-flex"
              :class="{ selected: $route.path.includes('suggestion') }"
              @click="$router.push({ name: 'Suggestion' })"
            >
              <img src="../assets/images/profile/group-icon.svg" alt="" />
              <p class="text-big">การแนะนำของฉัน</p>
            </div>
            <div
              class="item d-flex"
              :class="{ selected: $route.path.includes('paymenthistory') }"
              @click="$router.push({ name: 'PayHistory' })"
            >
              <img src="../assets/images/profile/history-icon.svg" alt="" />
              <p class="text-big">ประวัติการชำระเงิน</p>
            </div>
            <div class="item d-flex" @click="displayLogout = true">
              <img src="../assets/images/profile/logout-icon.svg" alt="" />
              <p class="text-big">ออกจากระบบ</p>
            </div>
          </div>
        </div>
        <router-view class="col-12 col-md-8"></router-view>
      </div>
    </div>
    <the-footer />
  </div>
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheFooter from "../components/TheFooter.vue";
import BaseDialog from "../components/BaseDialog.vue";
export default {
  components: {
    TheHeader,
    TheFooter,
    BaseDialog,
  },
  data() {
    return {
      displayLogout: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.menu {
  margin: 25% 0 0 0;
}

.menu p {
  margin: 0;
}

.menu .item {
  cursor: pointer;
  color: #7d7d7d;
  border-bottom: 1px solid #d5d5d5;
  padding: 30px 0;
}

.menu .item:first-child {
  color: #7d7d7d;
  border-top: 1px solid #d5d5d5;
}

.menu .item img {
  margin-right: 20px;
  width: 20px;
}

.menu .item.selected img,
.menu .item.selected p {
  filter: brightness(0) saturate(100%) invert(10%) sepia(77%) saturate(7482%)
    hue-rotate(231deg) brightness(79%) contrast(97%);
}

.mobile-menu {
  /* padding: 30px 0; */
  margin: 20px 0;
}

.mobile-menu .item {
  cursor: pointer;
  padding: 10px;
  border-bottom: 3px solid transparent;
}

.mobile-menu .item.selected {
  filter: brightness(0) saturate(100%) invert(10%) sepia(77%) saturate(7482%)
    hue-rotate(231deg) brightness(79%) contrast(97%);
  border-bottom: 3px solid #7d7d7d;
}

.logout button {
  width: 175px;
  padding: 10px 0;
  font-weight: 700;
  margin: 10px 10px;
}

.logout .cancel {
  color: #000;
  background-color: #c4c4c4;
}

.logout .confirm {
  color: #fff;
  background-color: #07c5f8;
}
</style>
