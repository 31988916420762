<template>
  <header :class="{ needPadding: needPadding }">
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand :to="{ name: 'Home' }">
        <!-- <img class="logo" src="../assets/images/logo.svg" alt="" /> -->
        <img
          class="logo"
          src="../assets/images/hod.png"
          style="width: 250px; object-fit:contain"
          alt=""
        />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-navbar-nav class="d-none d-lg-flex" is-nav>
        <b-nav-item class="mx-2" :to="{ name: 'Home' }"
          ><p class="text-big" :class="{ selected: $route.path === '/' }">
            หน้าแรก
          </p></b-nav-item
        >
        <b-nav-item class="mx-2" :to="{ name: 'Curriculum' }"
          ><p
            class="text-big"
            :class="{ selected: $route.path.includes('curriculum') }"
          >
            หลักสูตรทั้งหมด
          </p></b-nav-item
        >
        <b-nav-item class="mx-2" :to="{ name: 'About' }"
          ><p class="text-big" :class="{ selected: $route.path === '/about' }">
            เกี่ยวกับเรา
          </p></b-nav-item
        >
        <b-nav-item
          v-if="this.$store.getters.isAuth"
          class="mx-2"
          :to="{ name: 'MyCourse' }"
          ><p class="text-big">
            คอร์สเรียนของฉัน
          </p></b-nav-item
        >
      </b-navbar-nav>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="d-block d-lg-none">
          <b-nav-item class="mx-2" :to="{ name: 'Home' }"
            ><p class="text-big" :class="{ selected: $route.path === '/' }">
              หน้าแรก
            </p></b-nav-item
          >
          <b-nav-item class="mx-2" :to="{ name: 'Curriculum' }"
            ><p
              class="text-big"
              :class="{ selected: $route.path.includes('curriculum') }"
            >
              หลักสูตรทั้งหมด
            </p></b-nav-item
          >
          <b-nav-item class="mx-2" :to="{ name: 'About' }"
            ><p
              class="text-big"
              :class="{ selected: $route.path === '/about' }"
            >
              เกี่ยวกับเรา
            </p></b-nav-item
          >
          <b-nav-item
            class="mx-2"
            :to="{ name: 'MyCourse' }"
            v-if="this.$store.getters.isAuth"
            ><p class="text-big">
              คอร์สเรียนของฉัน
            </p></b-nav-item
          >
          <!-- <b-nav-item class="mx-2"><p class="text-big">ข่าวสาร</p></b-nav-item> -->
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto d-none d-lg-block">
          <div class="hello d-flex justify-content-end">
            <!-- <button class="enroll" @click="goToVideo"><p>Enroll</p></button> -->
            <button class="login" @click="goToProfile" v-if="isAuth">
              <p>โปรไฟล์</p>
            </button>
            <button class="login" @click="goToLogin" v-else>
              <p>Login / Register</p>
            </button>
          </div>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto d-block d-lg-none">
          <!-- <b-nav-item class="mx-2">
            <button class="enroll-collapse" @click="goToVideo">
              <p>Enroll</p>
            </button>
          </b-nav-item> -->
          <b-nav-item class="mx-2" v-if="isAuth">
            <button class="login-collapse" @click="goToProfile">
              <p>โปรไฟล์</p>
            </button>
          </b-nav-item>
          <b-nav-item class="mx-2" v-else>
            <button class="login-collapse" @click="goToLogin">
              <p>Login / Register</p>
            </button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
export default {
  emit: ["reload-login"],
  computed: {
    needPadding() {
      return (
        this.$route.path.includes("/lesson") ||
        this.$route.path.includes("/payment")
      );
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
  },
  methods: {
    goToLogin() {
      if (this.$route.path.includes("/login")) {
        this.$root.$emit("reload-login");
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    goToProfile() {
      this.$router.push({ name: "Profile" });
    },
    goToVideo() {
      this.$router.push({
        name: "Lesson",
        params: { videoUid: "64a6c17eae8e6b4fc7482642ce4f3ded" },
      });
    },
  },
};
</script>

<style scoped>
.navbar.navbar-light.bg-light {
  background-color: #fff !important;
  padding: 0;
}

.navbar-toggler {
  border: none;
  border-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
  max-height: 76px;
  width: 76px;
  height: 100%;
  /* background-color: #0430ad; */
}
/* .navbar-light >>> .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h44M4 16h44M4 24h44'/%3E%3C/svg%3E") !important;
} */

/* ทำให้เมนูชิดกัน */
/* .navbar >>> .nav-link {
  padding: 0.5rem 0;
} */

.logo {
  height: 50px;
  padding: 0 50px 0 30px;
  margin-top: 10px;
}

.needPadding {
  margin-bottom: 50px;
}

p {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

p.selected {
  color: #000;
  border-bottom: 3px solid #02c5f8;
}

.hello {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 200px;
}

.enroll {
  height: 100%;
  width: 50%;
  max-width: 500px;
  background-color: #07c5f8;
  border: none;
  color: #fff;
  padding: 10px;
}

.login {
  height: 100%;
  width: 100%;
  max-width: 500px;
  background-color: #0430ad;
  border: none;
  color: #fff;
  padding: 10px;
}

.enroll-collapse {
  background-color: #07c5f8;
  border: none;
  color: #fff;
  padding: 10px;
  width: 100%;
}

.login-collapse {
  background-color: #0430ad;
  border: none;
  color: #fff;
  padding: 10px;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .navbar.navbar-light.bg-light {
    padding: 0.5rem 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  .navbar >>> .nav-link {
    padding: 0.5rem;
  }
}
</style>
